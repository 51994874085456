import { FormEvent, forwardRef, FocusEventHandler, ChangeEventHandler } from 'react'
import classnames from 'classnames'
import { renderToStaticMarkup } from 'react-dom/server'
import btoa from 'abab/lib/btoa'
import styles from './Form.module.scss'
import { ArrowDown } from '../Icons'

interface FormProps {
  onSubmit: (event: FormEvent) => void
}
export const Form: React.FC<FormProps> = ({ onSubmit, children }) => {
  return (
    <form onSubmit={onSubmit} className={styles.wrapper}>
      {children}
    </form>
  )
}

interface InputProps {
  id: string
  type: string
  name: string
  defaultValue?: string // Used by react-hook-form
  autoComplete?: string
  placeholder?: string
  required?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
  hasError?: boolean
}
export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ autoComplete = 'on', type, name, placeholder, required, id, defaultValue, onBlur, onChange, onFocus, hasError }, ref) => (
    <input
      id={id}
      autoComplete={autoComplete}
      className={classnames(styles.input, { [styles.inputError]: hasError })}
      defaultValue={defaultValue == null ? defaultValue : ''}
      ref={ref}
      type={type}
      name={name}
      placeholder={placeholder}
      required={required}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
    />
  )
)

interface LabelProps {
  htmlFor: string
}
export const Label: React.FC<LabelProps> = ({ htmlFor, children }) => {
  return <label className={styles.label} htmlFor={htmlFor}>{children}</label>
}

interface SubmitProps {
  disabled?: boolean
  value: string
}
export const Submit: React.FC<SubmitProps> = ({ disabled, value }) => {
  return <input className={styles.submit} type="submit" value={value} disabled={disabled} />
}

export enum FeedbackType {
  SUCCESS = 'success',
  FAIL = 'fail',
  INFO = 'info',
}
interface FeedbackProps {
  type?: FeedbackType
}
export const Feedback: React.FC<FeedbackProps> = ({
  type = FeedbackType.INFO,
  children
}) => {
  const css = classnames(styles.feedback, {
    [styles.feedbackSuccess]: type === FeedbackType.SUCCESS,
    [styles.feedbackFail]: type === FeedbackType.FAIL,
    [styles.feedbackInfo]: type === FeedbackType.INFO
  })
  return <span className={css}>{children}</span>
}

export const Fieldset: React.FC = ({ children }) => {
  return <div className={styles.fieldset}>{children}</div>
}

interface SelectValue {
  label: string
  value: string
}
interface SelectProps {
  id: string
  name: string
  values: SelectValue[]
}
export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ name, id, values }, ref) => {
    const encodedComponent: string = btoa(renderToStaticMarkup(<ArrowDown />))
    const background = `url("data:image/svg+xml;base64,${encodedComponent}")`
    return (
      <select
        id={id}
        className={styles.select}
        ref={ref}
        style={{ backgroundImage: background }}
        name={name}
      >
        {values.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    )
  }
)

interface ButtonProps {
  onClick: (e: React.SyntheticEvent) => void
  disabled?: boolean
  type?: 'reset' | 'submit' | 'button'
  tiny?: boolean
}

export const Button: React.FC<ButtonProps> = ({ children, onClick, disabled, type, tiny = false }) => {
  return <button onClick={onClick} role='button' className={classnames(styles.button, { [styles['button--tiny']]: tiny })} disabled={disabled} type={type}>{children}</button>
}

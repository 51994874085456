import indexOf from 'lodash.indexof'
import { validateEmail } from '../lib/formHelper'
import { Frequency } from '../models/Frequency'
export enum GroupError {
  NAME_UNAVAILABLE = 'This name is not available',
  UNABLE_TO_CHECK = 'We were unable to check if this group name is available. Please try again in a few moments.'
}
export const checkNameAvailability = async (groupName: string): Promise<boolean> => {
  const response = await fetch('/api/group/is-available', {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({ groupName })
  })

  const responseBody = await response.json()
  if (response.status !== 200) {
    throw new Error(GroupError.UNABLE_TO_CHECK)
  }
  return Boolean(responseBody.available)
}

interface CreateGroupParams {
  groupName: string
  groupMembers: string[]
  frequency: string
}

export function validateFrequency (frequency: string): boolean {
  return indexOf([Frequency.WEEKLY, Frequency.BIWEEKLY, Frequency.MONTHLY], frequency) > -1
}

export function validateGroupMembers (members: string[]): boolean {
  return members.filter(email => validateEmail(email)).length > 0
}

export function validateGroupName (name: string): boolean {
  return name.trim() !== ''
}

export async function create ({ groupName, groupMembers, frequency }: CreateGroupParams): Promise<void> {
  if (!validateFrequency(frequency) || !validateGroupMembers(groupMembers) || !validateGroupName(groupName)) {
    throw new Error('Invalid group parameters!')
  }
  const response = await fetch('/api/group/create', {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({ name: groupName, members: groupMembers, frequency })
  })
  if (response.status !== 200) {
    throw new Error('Unable to create group this time.')
  }
}

import React, { useState, FocusEventHandler, ChangeEventHandler } from 'react'
import Link from 'next/link'
import { useForm, useFieldArray } from 'react-hook-form'
import debounce from 'lodash.debounce'
import classnames from 'classnames'
import { Form, Button as FormButton, Input, Label, Submit, Select, Feedback, FeedbackType } from '../../components/Form'
import { validateEmail } from '../../lib/formHelper'
import { checkNameAvailability, validateGroupMembers, create } from '../../services/group'
import { getClientLogger } from '../../services/logs.client'

import { Title, TitleLevel } from '../Typography'

import styles from './CreateGroup.module.scss'

enum GroupNameFieldStatus {
  INITIAL = 'initial',
  LOADING = 'loading',
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable'
}

enum Status {
  INITIAL = 'initial',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error'
}

interface CreateGroupForm {
  groupName: string
  groupMembers: GroupMemberInput[]
  frequency: string
}

interface GroupMemberInput {
  email: string
  placeholder: string
}

const initialPlaceholder = 'jane@austin.com'
const placeholders = [
  'sus@gmail.com',
  'may@outlook.com',
  'john@hotmail.com',
  'steve@icloud.com',
  'vini@letters.group',
  'bernardo@sony.com',
  'lisa@simpsons.com',
  'kenny@southpark.com',
  'random@chaos.com',
  'last@friends.com',
  'lord@byron.eu',
  'tim@timferris.com',
  'bill@microsoft.com',
  'woody@toys.inc',
  'buzz@toys.inc'
]

const getRandomPlaceholder: () => string = () => {
  const randomIndex = Math.floor(Math.random() * 15)
  const placeholder = placeholders[randomIndex]
  return placeholder != null ? placeholder : 'email@email.com'
}
export const CreateGroup: React.FC = () => {
  const [canAddMembers, updateCanAddMembers] = useState<boolean>(true)
  const [lastChecked, setLastChecked] = useState<string | null>()
  const [emailErrors, setErrors] = useState<{ [email: string]: boolean }>({})
  const [formStatus, setFormStatus] = useState<Status>(Status.INITIAL)
  const [nameStatus, setNameStatus] = useState<GroupNameFieldStatus>(GroupNameFieldStatus.INITIAL)
  const { control, register, handleSubmit, errors: formErrors, setError: setFormError, clearErrors: clearFormErrors } = useForm<CreateGroupForm>({
    defaultValues: { groupMembers: [{ email: '', placeholder: initialPlaceholder }] }
  })
  const { fields, append, remove } = useFieldArray<GroupMemberInput>({ control, name: 'groupMembers' })
  const onCreateGroupSubmit: (form: CreateGroupForm) => void = ({ frequency, groupMembers, groupName }) => {
    const hasErrors: boolean = formErrors.frequency != null ?? formErrors.groupMembers ?? formErrors.groupName
    if (groupMembers == null || !validateGroupMembers(groupMembers.map(({ email }) => email))) {
      setFormError(
        'groupMembers',
        {
          type: 'manual',
          message: 'You need at least one member to create a group'
        })
      setFormStatus(Status.ERROR)
      return
    }
    if (hasErrors) {
      setFormStatus(Status.ERROR)
      return
    }
    if (!hasErrors) {
      setFormStatus(Status.LOADING)
      create({ groupName, groupMembers: groupMembers.map(({ email }) => email), frequency })
        .then(() => {
          setFormStatus(Status.SUCCESS)
        }).catch(error => {
          getClientLogger().captureException(error)
          setFormStatus(Status.ERROR)
        })
    }
  }
  const onAddMember: () => void = () => {
    append({ email: '', placeholder: getRandomPlaceholder() })
    updateCanAddMembers(fields.length < 9)
  }
  const onRemoveMember: (index: number) => () => void = (index) => () => {
    remove(index)
    updateCanAddMembers(fields.length < 9)
  }
  const checkEmail = (index: number, email: string): void => {
    if (email === '') {
      return
    }
    if (!validateEmail(email)) {
      return setErrors({ ...emailErrors, [index]: true })
    }
    return setErrors({ ...emailErrors, [index]: false })
  }
  const resetEmailError = (index: number): void => {
    const email: string | undefined = fields[index].email
    if (email == null) {
      return
    }
    return setErrors({ ...emailErrors, [index]: false })
  }

  const resetGroupName: FocusEventHandler<HTMLInputElement> = (event) => {
    const groupName = event.currentTarget.value
    if (lastChecked === groupName) {
      return
    }
    clearFormErrors('groupName')
    setNameStatus(GroupNameFieldStatus.INITIAL)
  }

  const onGroupMemberBlur: (index: number) => FocusEventHandler<HTMLInputElement> = (index) => (event) => {
    clearFormErrors('groupMembers')
    checkEmail(index, event.currentTarget.value)
  }

  const checkGroupName: ChangeEventHandler<HTMLInputElement> = (event) => {
    const groupName = event.currentTarget?.value
    if (lastChecked === groupName) {
      return
    }
    setNameStatus(GroupNameFieldStatus.INITIAL)
    clearFormErrors('groupName')
    setLastChecked(groupName)
    if (groupName === '') {
      setFormError('groupName', { type: 'manual', message: 'This group name is not valid.' })
      setNameStatus(GroupNameFieldStatus.UNAVAILABLE)
      return
    }
    setNameStatus(GroupNameFieldStatus.LOADING)
    checkNameAvailability(groupName)
      .then((isAvailable) => {
        if (!isAvailable) {
          setFormError('groupName', { type: 'manual', message: 'This group name is not available.' })
          setNameStatus(GroupNameFieldStatus.UNAVAILABLE)
          return
        }
        setNameStatus(GroupNameFieldStatus.AVAILABLE)
        clearFormErrors('groupName')
      })
      .catch(error => {
        getClientLogger().captureException(error)
        setNameStatus(GroupNameFieldStatus.INITIAL)
        setFormError('groupName', { type: 'manual', message: 'Something went wrong while checking for your group name availability.' })
      })
  }
  const throttledCheckGroupName = debounce(checkGroupName, 500, { leading: false, trailing: true })
  const frequencyOptions = [
    {
      label: 'Weekly',
      value: '7d'
    },
    {
      label: 'Biweekly',
      value: '14d'
    },
    {
      label: 'Monthly',
      value: '1mo'
    }
  ]

  if (formStatus === Status.SUCCESS) {
    return (
      <div className={styles.createGroup} />
    )
  }
  return (
    <div className={styles.createGroup}>
      <Title level={TitleLevel.THREE}>Create Group</Title>
      <Form onSubmit={handleSubmit(onCreateGroupSubmit)}>
        <Label htmlFor='groupName'>Group name</Label>
        <div className={classnames(styles.inlineField, styles.short)}>
          <Input
            ref={register({ required: true })}
            id='groupName'
            name='groupName'
            placeholder='Best friends ever'
            type='text'
            autoComplete={'off'}
            onChange={throttledCheckGroupName}
            onBlur={checkGroupName}
            onFocus={resetGroupName}
          />
        </div>
        {nameStatus === GroupNameFieldStatus.INITIAL && formErrors.groupName == null && <Feedback type={FeedbackType.INFO}>A unique name for your group's newsletter</Feedback>}
        {nameStatus === GroupNameFieldStatus.LOADING && <Feedback type={FeedbackType.INFO}>Checking...</Feedback>}
        {formErrors.groupName != null && <Feedback type={FeedbackType.FAIL}>{formErrors.groupName.message}</Feedback>}
        {nameStatus === GroupNameFieldStatus.AVAILABLE && <Feedback type={FeedbackType.SUCCESS}>Looks good!</Feedback>}
        <Label htmlFor='group-frequency'>Newsletter frequency</Label>
        <Select ref={register()} id='group-frequency' name='frequency' values={frequencyOptions} />
        {formErrors.frequency == null && <Feedback type={FeedbackType.INFO}>How often do you want your group letters to be sent</Feedback>}
        {formErrors.frequency != null && <Feedback type={FeedbackType.FAIL}>{formErrors.frequency.message}</Feedback>}
        <Label htmlFor='group-member[0]'>Members <Feedback>Max. 10</Feedback></Label>
        {fields.map((item, index) => (
          <div className={styles.inlineField} key={item.placeholder}>
            <div className={styles.inlineField}>
              <Input
                ref={register()}
                id={`group-member[${index}]`}
                name={`groupMembers[${index}].email`}
                required
                placeholder={item.placeholder}
                type="email"
                defaultValue={item.email}
                onBlur={onGroupMemberBlur(index)}
                onFocus={() => resetEmailError(index)}
                autoComplete={'email'}
                hasError={!!emailErrors[index]}
              />
              {index !== 0 && <FormButton onClick={onRemoveMember(index)} type='button' tiny>Remove</FormButton>}
            </div>
            {emailErrors[index] && <Feedback type={FeedbackType.FAIL}>This email does not look right</Feedback>}
          </div>
        ))}
        <FormButton onClick={onAddMember} disabled={!canAddMembers} type='button' tiny>Add member</FormButton>
        {formErrors.groupMembers == null && <Feedback type={FeedbackType.INFO}>You can always invite others later</Feedback>}
        {formErrors.groupMembers != null && <Feedback type={FeedbackType.FAIL}>You need at least one other member to create a group</Feedback>}
        <div className={styles.buttons}>
          <Submit value={formStatus === Status.LOADING ? 'Creating...' : 'Create group'} disabled={formStatus === Status.LOADING} />
          {formStatus === Status.ERROR && <p><Feedback type={FeedbackType.FAIL}>Something went wrong. Please try again in a few moments, or get in touch via <a href="mailto:support@letters.group">support@letters.group</a>.</Feedback></p>}
        </div>
      </Form>
      <Link href='/app'><a>&larr; Back to Dashboard</a></Link>
    </div >
  )
}

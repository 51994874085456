import * as SentryClient from '@sentry/browser'

let clientLogger: typeof SentryClient | null = null
const isServer: boolean = typeof window === 'undefined'
const isProd: boolean = process.env.NODE_ENV === 'production'
interface DevLogger {
  captureException: (error: Error) => void
}

export const getClientLogger = (): typeof SentryClient | DevLogger => {
  if (isServer && isProd) {
    throw new Error('Trying to get client logger on server')
  }

  if (!isProd) {
    // eslint-disable-next-line no-console
    return { captureException: console.error }
  }

  if (clientLogger == null) {
    clientLogger = SentryClient
  }
  return clientLogger
}

import { useEffect, useState } from 'react'
import Router from 'next/router'

export const isServer = (): boolean => typeof window === 'undefined'

export const getQueryStringParams = (query: string): {[key: string]: string} => {
  return (/^[?#]/.test(query) ? query.slice(1) : query)
    .split('&')
    .reduce((params, param) => {
      const [key, value] = param.split('=')
      params[key] = value != null ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''
      return params
    }, {}
    )
}

export function generateRandomString (length: number): string {
  const a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.split('')
  const b = []
  for (let i = 0; i < length; i++) {
    const j = (Math.random() * (a.length - 1)).toFixed(0)
    // @ts-expect-error
    b.push(a[j])
  }
  return b.join('')
}

export const useHashRoute = (): { hashRoute: string } => {
  const [hashRoute, setHashRoute] = useState<string>('')
  useEffect(() => {
    const urlHash = window.location.hash
    setHashRoute(urlHash)
  }, [])
  Router.events.on('hashChangeComplete', () => {
    const urlHash = window.location.hash
    setHashRoute(urlHash)
  })
  return { hashRoute }
}

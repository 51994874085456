import React, { ReactNode, useEffect, useState } from 'react'
import Link from 'next/link'
import { CreateGroup } from '../../components/Form/CreateGroup'
import { Button } from '../../components/Button'
import { DashboardTemplate } from '../../components/DashboardTemplate'
import { InTouch } from '../../components/Illustrations'
import { Body, Small, Title, TitleLevel } from '../../components/Typography'
import { useHashRoute } from '../../lib/utils'
import { Group } from '../../models/Group'
import { useUserSession } from '../../services/session'

import styles from './index.module.scss'

enum hashRoutes {
  CREATE_GROUP = '#create-group'
}

const Error: React.FC<{ message: ReactNode }> = ({ message }) => (
  <>
    <Title level={TitleLevel.TWO}>Something went wrong...</Title>
    <Body>{message}</Body>
  </>
)

const Loading: React.FC = () => (
  <>
    <Title level={TitleLevel.TWO}>Dashboard</Title>
    <Body>Getting user information...</Body>
  </>
)

const Start: React.FC = () => (
  <div className={styles.start}>
    <InTouch className={styles.startIllustration} />
    <Body>You're not a member of any group. <strong>Get an invite</strong>, or <strong>create your own group</strong> and invite others.</Body>
    <Button href={`/app${hashRoutes.CREATE_GROUP}`}>Create group</Button>
  </div>
)

interface OverviewProps {
  hashRoute: string
  memberGroups: Group[]
  ownGroups: Group[]
}
const Overview: React.FC<{ memberGroups: Group[], ownGroups: Group[] }> = ({ memberGroups, ownGroups }) => {
  return (
    <div className={styles.overview}>
      <ul>
        {ownGroups.map(({ name }) => <li key={name}>{name}</li>)}
      </ul>
      <ul>
        {memberGroups.map(({ name }) => <li key={name}>{name}</li>)}
      </ul>
    </div>
  )
}

const View: React.FC<OverviewProps> = ({ hashRoute, memberGroups, ownGroups }) => {
  switch (hashRoute) {
    case hashRoutes.CREATE_GROUP:
      return <CreateGroup />
    default:
      if ((memberGroups.length + ownGroups.length) === 0) {
        return <Start />
      }
      return <Overview memberGroups={memberGroups} ownGroups={ownGroups} />
  }
}

const Dashboard: React.FC = () => {
  const { user } = useUserSession()
  const [error, setError] = useState<boolean>(false)
  const { hashRoute } = useHashRoute()
  useEffect(() => {
    const timer = setTimeout(() => {
      if (user == null) {
        setError(true)
      }
    }, 4000)
    return () => {
      clearTimeout(timer)
    }
  }, [error, user])

  if (error) {
    return (
      <DashboardTemplate>
        <Error message={<>We were unable to get your information. Please <Link href={'/login'}>log in</Link> and try again.</>} />
      </DashboardTemplate>
    )
  }

  if (user == null) {
    return (
      <DashboardTemplate>
        <Loading />
      </DashboardTemplate>
    )
  }
  const { username, email, memberGroups, ownGroups } = user
  return (
    <DashboardTemplate>
      <div className={styles.top}>
        <Title level={TitleLevel.TWO}>Dashboard</Title>
        <Small>Logged in as: <strong>{username}</strong> {`< ${email}>`}</Small>
      </div>
      <View memberGroups={memberGroups} ownGroups={ownGroups} hashRoute={hashRoute} />
    </DashboardTemplate>
  )
}

export default Dashboard

import { serialize, parse } from 'cookie'
import Iron from '@hapi/iron'
import { NextApiRequest, NextApiResponse } from 'next'
import { useEffect } from 'react'
import Router from 'next/router'
import useSWR from 'swr'
import { User } from '../models/User'
import { getClientLogger } from './logs.client'

interface Cookies { [key: string]: string }

const TOKEN_NAME = 'unicorn'
const MAX_AGE = 60 * 60 * 8

export function setTokenCookie (res: NextApiResponse, token: string): void {
  const cookie = serialize(TOKEN_NAME, token, {
    maxAge: MAX_AGE,
    expires: new Date(Date.now() + MAX_AGE * 1000),
    httpOnly: true,
    secure: process.env.NODE_ENV === 'production',
    path: '/',
    sameSite: 'lax'
  })
  res.setHeader('Set-Cookie', cookie)
}

export function removeTokenCookie (res: NextApiResponse): void {
  const cookie = serialize(TOKEN_NAME, '', {
    maxAge: -1,
    path: '/'
  })

  res.setHeader('Set-Cookie', cookie)
}

export function parseCookies (req: NextApiRequest): Cookies {
  // For API Routes we don't need to parse the cookies.
  if (req.cookies != null) return req.cookies

  const cookie = req.headers?.cookie
  return parse(cookie ?? '')
}

export function getTokenCookie (req: NextApiRequest): string {
  const cookies = parseCookies(req)
  return cookies[TOKEN_NAME]
}

const { SESSION_SECRET: secret } = process.env

interface Session {
  email: string
  secret?: string
}

export async function encryptSession (session: Session): Promise<string> {
  if (secret == null) {
    throw new Error('Unable to encrypt.')
  }
  return await Iron.seal(session, secret, Iron.defaults)
}

export async function getSession (req: NextApiRequest): Promise<Session | null> {
  const token = getTokenCookie(req)
  if (token == null || secret == null) {
    return null
  }
  return await Iron.unseal(token, secret, Iron.defaults)
}

const fetcher = async (url: string): Promise<{ user: User | null }> =>
  await fetch(url)
    .then(async (r) => await r.json())
    .then((data) => {
      const user = data == null ? null : data.user
      return { user }
    })

interface UseUserArgs { redirectTo?: string, redirectIfFound?: string }

interface UserSessionResponse {
  user: User | null
  error: Error | null
  finished: boolean
}
export function useUserSession ({ redirectTo, redirectIfFound }: UseUserArgs = {}): UserSessionResponse {
  const { data, error } = useSWR('/api/user', fetcher)
  const user = data != null ? data.user : null
  const finished = Boolean(data)
  const hasUser = Boolean(user)

  useEffect(() => {
    if ((redirectTo == null && redirectIfFound == null)) return
    // If redirectTo is set, redirect if the user was not found.
    if (redirectTo != null && !hasUser && finished) {
      Router.push(redirectTo).catch(error => getClientLogger().captureException(error))
      return
    }

    // If redirectIfFound is also set, redirect if the user was found
    if (redirectIfFound != null && hasUser && finished) {
      Router.push(redirectIfFound).catch(error => getClientLogger().captureException(error))
    }
  }, [redirectTo, redirectIfFound, finished, hasUser])

  return { user, error, finished }
}

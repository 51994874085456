import { DashboardHeader } from '../Header'
import styles from './DashboardTemplate.module.scss'

export const DashboardTemplate: React.FC = ({ children }) => {
  return <main className={styles.dashboard}>
    <DashboardHeader />
    <section className={styles.dashboardContent}>
      {children}
    </section>
  </main>
}
